import * as React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import CategoryRenderer from '../section-renderer/category'

const CategoryPage = ({ location, data, pageContext }) => {
  return (
    <CategoryRenderer location={location} data={data} pageContext={pageContext} />
  )
}

export const query = graphql`
  query ($categoryId: Int!, $perPage: Int!, $skip: Int!) {
    facets: allStrapiProduct(
      sort: { fields: stock_date, order: DESC }
      filter: { categories: { elemMatch: { strapi_id: { eq: $categoryId } } }, qty: { gt: 0 } }
    ) {
      ...FacetFilters
    }
    items: allStrapiProduct(
      sort: { fields: [in_stock, stock_date], order: [DESC, DESC] }
      limit: $perPage
      skip: $skip
      filter: { categories: { elemMatch: { strapi_id: { eq: $categoryId } } } }
    ) {
      nodes {
        ...ProductCard
      }
      pageInfo {
        perPage
        pageCount
        totalCount
        itemCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
    subcategories: allStrapiCategory(filter: { strapi_parent: { id: { eq: $categoryId } } }) {
      nodes {
        name
        url: urlPath
      }
    }
    category: strapiCategory(strapi_id: { eq: $categoryId }) {
      strapi_id
      name
      description
      url: urlPath
      headerH1
      description
      descriptionTop
      descriptionBottom
      currency
      videos {
        heading
        videos {
          url
          title
          thumbnail {
            gatsbyImageDataMock
          }
        }
      }
      breadcrumbs {
        url: urlPath
        name
      }
      seo {
        ...SEO
      }
      sections {
        ... on STRAPI__COMPONENT_SECTION_PARAGRAPH {
          ...ParagraphSection
        }
        ... on STRAPI__COMPONENT_SECTION_ACCORDION_SECTION {
          ...AccordionSection
        }
        # ... on STRAPI__COMPONENT_SHARED_LINK {
        #   ...CtaLink
        # }
        ... on STRAPI__COMPONENT_SECTION_TESTIMONIALS {
          ...TestimonialsSection
        }
        # ... on STRAPI__COMPONENT_SECTION_CONTACT_US_MAP {
        #   strapi_component
        # }
        ... on STRAPI__COMPONENT_SECTION_FORM {
          ...FormSection
        }
        ... on STRAPI__COMPONENT_SECTION_IMAGE_SECTION {
          ...ImageSection
        }
        ... on STRAPI__COMPONENT_SECTION_NUMBERED_LIST {
          ...NumberedListSection
        }
        ... on STRAPI__COMPONENT_SECTION_SECTION_LAYOUT {
          ...SectionLayout
        }
        # ... on STRAPI__COMPONENT_SECTION_COMMON_QUESTIONS {
        #   ...HaveQuestionsSection
        # }
        # ... on STRAPI__COMPONENT_SECTION_PRODUCT_GRID {
        #   ...ProductGridSection
        # }
      }
    }
  }
`
CategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default CategoryPage
